<div class="ion-text-center">
  @if (image) {
    <img [src]="src"/>
  }

  @if (title) {
    <h2>{{title}}</h2>
  }

  @if (firstContent) {
    <p>{{firstContent}}
      @if (link) {
        <a [href]="link" target="_blank">{{link}}</a>
      }
    </p>
  }

  @if (secondContent) {
    <p>{{secondContent}}</p>
  }
</div>
